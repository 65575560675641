<template>
  <div class="center content-status">
    <div style="float:left;width:100%">
      <el-form
        :inline="true"
        :model="queryParam"
      >
        <el-form-item>
          <el-input
            @clear="initData()"
            clearable
            style="width:350px"
            v-model="queryParam.name"
            :placeholder="matchDetail.id == hideMatchId ? '参赛者姓名/参赛者单位/学校搜索':'团队名称/指导教师/团队成员搜索'"
            @change="initData()"
          >
            <el-button
              @click="initData()"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width:200px"
            clearable
            @change="initData()"
            v-model="queryParam.level"
            placeholder="参赛组别筛选"
          >
            <el-option
              v-for="(level,i) in matchDetail.levelSetting"
              :label="level.label"
              :key="i"
              :value="level.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeMenu == 'join'">
          <el-select
            style="width:200px"
            clearable
            @change="initData()"
            v-model="queryParam.status"
            placeholder="报名状态筛选"
          >
            <el-option
              label="信息审核中"
              value="1"
            ></el-option>
            <el-option
              label="报名成功"
              value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeMenu == 'works'">
          <el-select
            style="width:200px"
            clearable
            @change="initData()"
            v-model="queryParam.workSubmitStatus"
            placeholder="提交状态筛选"
          >
            <el-option
              label="未提交"
              :value="0"
            ></el-option>
            <el-option
              label="已提交"
              :value="1"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-if="matchDetail.id == hideMatchId"
      :data="joinFormData"
      border
      style="width: 100%"
    >
      <el-table-column
        type="index"
        label="序号"
        width="100"
      >
      </el-table-column>
      <el-table-column
        v-if="activeMenu == 'works'"
        prop="stageName"
        label="比赛阶段"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        width="200"
        label="参赛者姓名"
      >
      </el-table-column>
      <el-table-column
        prop="matchLevelId"
        label="组别"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="leader"
        label="参赛者单位/学校"
      >
      </el-table-column>
      <el-table-column
        v-if="activeMenu == 'join'"
        label="报名状态"
        width="120"
      >
        <template slot-scope="scope">
          {{scope.row.status | statusFilter}}
        </template>
      </el-table-column>
      <el-table-column
        v-if="activeMenu == 'works'"
        label="提交状态"
        width="120"
      >
        <template slot-scope="scope">
          {{scope.row.workSubmitStatus | workFilter}}
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-else
      :data="joinFormData"
      border
      style="width: 100%"
    >
      <el-table-column
        type="index"
        label="序号"
        width="100"
      >
      </el-table-column>
      <el-table-column
        v-if="activeMenu == 'works'"
        prop="stageName"
        label="比赛阶段"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="团队名称"
      >
      </el-table-column>
      <el-table-column
        prop="matchLevelId"
        label="组别"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="leader"
        label="指导老师"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="leader"
        label="团队成员"
        width="200"
      >
        <template slot-scope="scope">
          {{scope.row.members | membersFilter}}
        </template>
      </el-table-column>
      <el-table-column
        v-if="activeMenu == 'join'"
        label="报名状态"
        width="120"
      >
        <template slot-scope="scope">
          {{scope.row.status | statusFilter}}
        </template>
      </el-table-column>
      <el-table-column
        v-if="activeMenu == 'works'"
        label="提交状态"
        width="120"
      >
        <template slot-scope="scope">
          {{scope.row.workSubmitStatus | workFilter}}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { hideMatchId, getApplySatus } from '@/api/match';

export default {
  props: {
    activeMenu: String,
  },
  data() {
    return {
      hideMatchId,
      joinFormData: [],
      queryParam: {
        level: null,
        name: null,
        status: null,
        workSubmitStatus: null
      }
    };
  },
  computed: {
    matchDetail() {
      // console.log(this.$store.state.matchDetail)
      return this.$store.state.matchDetail;
    },
    matchActiveTab() {
      return this.$store.state.matchActiveTab;
    },
    matchStatus() {
      return this.failStatus ? this.failStatus : this.activeMenu;
    },
  },
  watch: {
    activeMenu(oldVal, newVal) {
      if (oldVal != newVal) {
        this.initData();
      }
    }
  },
  filters: {
    statusFilter(val) {
      if (val == 1) {
        return '信息审核中'
      } else if (val == 2) {
        return '报名成功'
      } else if (val == 3) {
        return '报名失败'
      }
    },
    membersFilter(members) {
      let str = [];
      for (let i in members) {
        str.push(members[i].name)
      }
      return str.join(',');
    },
    workFilter(val) {
      if (val == 0) {
        return '未提交';
      } else if (val == 1) {
        return '已提交';
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      let param = this.queryParam;
      param.matchId = this.matchDetail.id;
      getApplySatus(param).then((res) => {
        const { result = {}, success } = res;
        if (!success) {
          this.joinFormData = [];
          return;
        } else {
          this.joinFormData = result.allTeams;
          for (let i in this.joinFormData) {
            if (this.joinFormData[i].members) {
              let row = this.joinFormData[i];
              for (let j in row.members) {
                row['member' + j + 'Name'] = row.members[j].name;
                row['member' + j + 'Identity'] = row.members[j].identity;
                row['member' + j + 'Contact'] = row.members[j].contact;
                row['member' + j + 'School'] = row.members[j].school;
                row['member' + j + 'Grade'] = row.members[j].grade;
                row['member' + j + 'Class'] = row.members[j].className;
              }
            }
          }
        }
      })
    },
  },
};
</script>

<style scoped>
.info-form /deep/ .el-form-item__error {
  /* top: 75%;
  left: -33px; */
}
.form-type {
  border-left: 3px solid #aaaaaa;
  padding-left: 10px;
  width: 800px;
  height: 26px;
  /* border: 2px solid #aaaaaa; */
  background-color: rgba(255, 255, 255, 0);
  /* box-sizing: border-box; */
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #aaaaaa;
  text-align: left;
  line-height: 18px;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
