<template>
  <div class="match-detail">
    <div class="match-detail-head center">
      <div class="head-banner">
         <div class="banner-btn">
        <el-dropdown v-if="isLogin">
          <span class="el-dropdown-link">
            <el-avatar style="width: 30px;height:30px;margin-right: 10px;"
            :src="userInfo.avatar"></el-avatar>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button type="text" @click="logoutConfirm">退出</el-button>
              </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <template v-else>
          <el-button @click="toLogin()" class="my-btn">登录</el-button>
          <el-button @click="toRegister()" class="my-btn">注册</el-button>
        </template>
      </div>
        <img class="banner-img" :src="matchDetail.bigCover">
        <div class="banner-match-info">
          <div class="item-title" style="-webkit-box-orient: vertical" title="">
            <span>{{ matchDetail.matchSeriesName }}</span
            ><br />
            <span
              >{{ matchDetail.title }}({{ matchDetail.matchProjectName }})</span
            >
          </div>
          <div class="item-info">
            <div class="flex info-unit">
              <div>主办单位：</div>
              <div>
                <span>{{
                  matchDetail.organizers && matchDetail.organizers.length
                    ? matchDetail.organizers[0]
                    : ''
                }}</span
                ><br />
                <span>{{
                  matchDetail.organizers && matchDetail.organizers.length > 1
                    ? matchDetail.organizers[1]
                    : ''
                }}</span>
              </div>
            </div>
            <div class="flex info-time">
              <div>活动时间：</div>
              <div>
                {{ $moment(matchDetail.startTime).format('YYYY-MM-DD') }}
                ---
                {{ $moment(matchDetail.endTime).format('YYYY-MM-DD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="head-actions">
        <div class="head-menus">
          <template v-if="matchId == hideMatchId">
            <template v-for="(menu, index) in menus">
            <template v-if="menu.name == '04 查看成绩'">
               <div v-if="matchId != hideMatchId"
            class="menu-div2 center"
            :class="{ 'active-menu': activeMenu == menu.key }"
            @click="changeActiveMenu(menu.key)"
          >
            <div :class="menu.icon" class="icon" />
            {{ menu.name }}
          </div>
            </template>
           
            <div v-else
            class="menu-div2 center"
            :class="{ 'active-menu': activeMenu == menu.key }"
            @click="changeActiveMenu(menu.key)"
          >
            <div :class="menu.icon" class="icon" />
            {{ menu.name }}
          </div>
          </template>
          </template>
          <template v-else>
            <template v-for="(menu, index) in menus">
            <div class="menu-div center"
            :class="{ 'active-menu': activeMenu == menu.key }"
            @click="changeActiveMenu(menu.key)"
          >
            <div :class="menu.icon" class="icon" />
            {{ menu.name }}
          </div>
          </template>
          </template>
          
          
        </div>
        <div class="separatorLine"></div>
        <div class="head-tabs">
          <el-row>
            <el-col :span="24">
              <el-tabs
              ref="tabs"
            v-model="matchActiveTab[activeMenu]['name']"
            @tab-click="changeActiveTab"
          >
          <template v-for="(tab, index) in tabs[activeMenu]">
            <template v-if="tab.name == '查看赛事要求' || tab.name == '赛事评审奖项' 
            || tab.name == '赛事附件下载' || tab.name == '查看报名队伍'|| tab.name == '我的报名状态'">
               <el-tab-pane
              v-if=" matchId != hideMatchId"
              :key="index"
              :label="tab.name"
              :name="tab.key"
              :type="tab.type"
            ></el-tab-pane>
            </template>
             <el-tab-pane
             v-else
              :key="index"
              :label="tab.name"
              :name="tab.key"
              :type="tab.type"
            ></el-tab-pane>
          </template>
          </el-tabs>
          </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="match-detail-content center">
      <MatchDeatilContent :activeMenu="activeMenu"></MatchDeatilContent>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/auth';
import { MessageBox } from 'element-ui';

import MatchDeatilContent from '@/components/match/index.vue';
import { hideMatchId,URL_API, getMatchDetailById } from '@/api/match';
import { mapState } from 'vuex';
import Vue from 'vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';
export default {
  data() {
    return {
      hideMatchId,
      matchId:null,
      activeMenu: 'detail',
      contentType: 'img',
      tabs: {
        detail: [
          { name: '查看赛事介绍', key: '1', type: 'img' },
          { name: '查看赛事要求', key: '2', type: 'img' },
          { name: '赛事评审奖项', key: '3', type: 'img' },
          { name: '赛事附件下载', key: '4', type: 'attachment' },
        ],
        join: [
          { name: '填写报名信息', key: '1', type: 'form' },
          { name: '我的报名状态', key: '2', type: 'status' },
          { name: '查看报名队伍', key: '3', type: 'table' },
        ],
        works: [
          { name: '提交我的作品', key: '1', type: 'upload' },
          { name: '查看提交状态', key: '2', type: 'status' },
          { name: '查看报名队伍', key: '3', type: 'table' },
        ],
        result: [
          { name: '查看我的成绩', key: '1', type: 'result' },
          { name: '赛事成绩公告', key: '2', type: 'table' },
          { name: '成绩投诉反馈', key: '3', type: 'complain' },
        ],
      },
      menus: [
        {
          name: '01 赛事详情',
          key: 'detail',
          icon: 'detail',
        },
        {
          name: '02 报名参赛',
          key: 'join',
          icon: 'join',
        },
        {
          name: '03 提交作品',
          key: 'works',
          icon: 'works',
        },
        {
          name: '04 查看成绩',
          key: 'result',
          icon: 'result',
        },
      ],
    };
  },
  computed: {
    isLogin: function() {
      let userInfo = getUserInfo();
      return !!userInfo;
    },
    userInfo: function() {
      return getUserInfo();
    },
    ...mapState({
      matchDetail: (state) => state.matchDetail,
      matchActiveTab: (state) => state.matchActiveTab,
      token: (state) => state.token,
      matchList: (state) => state.matchList,
    })
  },
  watch: {
      currentLang(){
        this.$nextTick(()=>{
          resetActivePosition(this.$refs.tabs.$el)
        })
      }
  },
  created: function () {
    let that = this;
    const id = this.$route.query.id;
    this.matchId = id;
    //1. 发送请求
    getMatchDetailById({ id: id })
      .then((res) => {
        if (!res.success) {
          //获取比赛详情失败
          that.$notify({
            title: '信息提示',
            message: '获取比赛详情失败!',
            type: 'warning',
          });
          return;
        }
        const { result } = res;
        let groupTypeOptions = [];
        const level = JSON.parse(result.levelSetting);
        level.map((item) => {
          groupTypeOptions.push({ label: item, value: item });
        });
        result.organizers = result.organizers.replace(/，/gi, ',');
        result.organizers = result.organizers.split(',');
        result.levelSetting = groupTypeOptions;
        this.$store.dispatch('SetMatchDetail', { matchDetail: result });
      })
      .catch((error) => {});
      this.$nextTick(()=>{
        this.resetActivePosition(this.$refs.tabs.$el)
      })
  },
  updated: function () {
    const token = Vue.ls.get(ACCESS_TOKEN);
    if (this.activeMenu !== 'detail' && !token) {
      console.log('没有登录，请先登录');
      this.$router.push({ path: `/login?redirect_uri=${location.href}` });
      // window.location.href = window.location.origin+"/passport/dologin?url="+encodeURI(location.href)
    }
  },
  destroyed: function () {
    this.$store.dispatch('SetMatchDetail', { matchDetail: {} });
    this.matchActiveTab['detail'] = { name: '1', type: 'img' };
    this.matchActiveTab['join'] = { name: '1', type: 'form' };
    this.matchActiveTab['works'] = { name: '1', type: 'upload' };
    this.matchActiveTab['result'] = { name: '1', type: 'result' };
  },
  methods: {
    logoutConfirm: function() {
      let that = this;
      MessageBox({
        type: 'warning',
        title: '系统提示',
        message: '您确定要退出登录吗',
      }).then(() => {
        that.$store.dispatch('LogoutUser');
        window.location.reload();
      });
    },
    toLogin(){
      this.$router.push(`/login?redirect_uri=${location.href}`);
    },
    toRegister(){
      this.$router.push({
        path: '/login',
        query: {
          type: 'reg',
        },
      });
    },
    changeActiveMenu(currentKey) {
      this.activeMenu = currentKey;
      this.contentType = this.tabs[this.activeMenu][0].type;
      this.$nextTick(() => {
        this.resetActivePosition(this.$refs.tabs.$el)
      })
    },
    changeActiveTab(tab) {
      const index = parseInt(tab.index);
      const contentType = this.tabs[this.activeMenu][index].type;
      this.matchActiveTab[this.activeMenu] = {
        name: tab.name,
        type: contentType,
      };
      this.$nextTick(() => {
        this.resetActivePosition(this.$refs.tabs.$el)
      })
    },
    resetActivePosition($el) {
      this.$nextTick(() => {
        const activeEl = $el.querySelector('.el-tabs__item.is-active');
        const lineEl = $el.querySelector('.el-tabs__active-bar');
        const style = getComputedStyle(activeEl);
        const pl = style.paddingLeft.match(/\d+/)[0] * 1;
        const pr = style.paddingRight.match(/\d+/)[0] * 1;
        const w = style.width.match(/\d+/)[0] * 1;
        lineEl.style.transform = 'translateX(' + (activeEl.offsetLeft + pl) + 'px)';
        lineEl.style.width = (w - pl - pr)+'px';
      })
    }
  },
  components: { MatchDeatilContent },
};
</script>
<style scoped>
.el-card /deep/ .el-card__body {
  display: flex;
  width: 100%;
  padding: 0;
}
.el-tabs /deep/ .el-tabs__item {
  font-weight: bold;
  color: #fff;
  padding: 0 58px !important;
}
.el-tabs /deep/ .el-tabs__nav-scroll {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.el-tabs /deep/ .is-active {
  color: #ffff00;
}
.el-tabs /deep/ .el-tabs__active-bar {
  height: 3px;
  background-color: #ffff00;
  margin-right: 58px;
}
.el-tabs /deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/match.scss';
.banner-img{
  position: absolute;
  width: 100%;
  height: 330px;
  z-index: 1;
}

</style>
