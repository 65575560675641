<template>
  <div
    class="center content-upload"
    v-loading="loading"
  >
    <el-form
      ref="uploadForm"
      :model="formData"
      label-width="140px"
      class="demo-formData"
      status-icon
    >
      <el-form-item
        v-for="item in formItems[activeMenu]"
        :key="item.dataIndex"
        :label="item.label + ':'"
        :prop="item.dataIndex"
        :rules="item.rules"
      >
        <el-input
          v-if="item.inputType === 'input'"
          v-model="formData[item.dataIndex]"
          v-bind:placeholder="'请输入' + item.label"
          :disabled="uploadStatus"
          style="width: 640px"
        ></el-input>
        <el-input
          v-if="item.inputType === 'textarea'"
          v-model="formData[item.dataIndex]"
          v-bind:placeholder="'请输入' + item.label"
          type="textarea"
          :rows="5"
          style="width: 640px"
          :disabled="uploadStatus"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="formItems.attach.label"
        :prop="formItems.attach.dataIndex"
        :rules="formItems.attach.rules"
      >
        <el-upload
          ref="upload"
          drag
          action="/api/teaching/matchUpload/upload"
          multiple
          :limit="5"
          :data="formData"
          :headers="headers"
          :file-list="fileList"
          :on-exceed="handleExceed"
          :on-preview="handlePreview"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :on-success="handleFileSuccess"
          :on-error="handleFileError"
          :on-progress="handleProgress"
          :disabled="uploadStatus"
          style="width: 640px"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <div style="font-weight:bold;margin-bottom:5px">点击或将文件拖拽到这里上传</div>
            <div>最多可以上传5个附件</div>
            <div>附件可以是视频、文件或者压缩包</div>
            <div>全部附件累计存储大小限制在500M以内</div>
            <div style="margin-top:15px">视频支持：mp4、mov格式；文件支持：PDF、Word格式；压缩包支持：zip、rar格式</div>
          </div>
          <div
            v-if="activeMenu === 'works'"
            class="el-upload__tip"
            slot="tip"
          >
            温馨提示：比赛截止前可重复提交多次，本次提交会覆盖上一次提交
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div class="submit-buttons">
          <el-button
            type="primary"
            @click="submitUpload"
            :disabled="!canSubmit || uploadStatus"
          >提交</el-button>
        </div>
      </el-form-item>
    </el-form>
    <!-- </div> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { getApplySatus, getWorksStatus, getMyResult, getComplaint, submitWorksMulti } from '@/api/match';
import { sensitiveFilter } from '@/api/common'
const sensitiveValidator = (rule, value, callback) => {
  sensitiveFilter({ desc: value }).then(res => {
    if (!res.success) {
      callback(new Error(res.message));
    } else {
      callback();
    }
  })
}
const token = Vue.ls.get(ACCESS_TOKEN);
export default {
  props: {
    activeMenu: String,
  },
  data() {
    return {
      isApply: true,
      hasComplaint: false,
      formData: {
        name: '',
        description: '',
        stageId: '',
        teamId: '',
        files: []
      },
      headers: {
        'X-Access-Token': token,
      },
      uploadStatus: true,
      canSubmit: true,
      attach: '', //默认值为空
      formItems: {
        works: [
          {
            dataIndex: 'name',
            label: '作品名称',
            inputType: 'input',
            rules: {
              required: true,
              message: '请输入作品名称,并在32个字符以内',
              trigger: 'change',
              max: 32,
            },
          },
          {
            dataIndex: 'description',
            label: '作品说明',
            inputType: 'textarea',
            rules: {
              required: true,
              message: '请输入作品说明，并在150字符以内',
              trigger: 'change',
              max: 150,
            },
          },
        ],
        attach: {
          dataIndex: 'fileList',
          label: this.activeMenu === 'works' ? '上传附件' : '材料支撑',
          inputType: 'upload',
          rules: {
            required: true,
            message: '请选择需要上传的附件',
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!this.fileList) {
                callback(new Error('请选择需要上传的附件'));
              } else {
                callback();
              }
            },
          },
        },
        result: [
          {
            groupType: '',
            dataIndex: 'groupType',
            label: '投诉/反馈作品',
            inputType: 'input',
            rules: [{
              required: true,
              message: '请输入需要投诉或反馈的作品名称',
              trigger: 'change',
              max: 32,
            },
            { validator: sensitiveValidator, trigger: 'change' }
            ],
          },
          {
            teamName: '',
            dataIndex: 'teamNameq',
            label: '投诉/反馈说明',
            inputType: 'textarea',
            rules: [{
              required: true,
              message: '请输入投诉/反馈说明，并在150个字符以内',
              trigger: 'change',
              max: 150,
            },
            { validator: sensitiveValidator, trigger: 'change' }
            ],
          },
        ],
      },
      message: '',
      fileList: [],
      loading: false,
      currentStatus: 'unStart',
    };
  },
  computed: {
    matchDetail() {
      return this.$store.state.matchDetail;
    }
  },
  watch: {
    formData: {
      handler(val) {
        if (!val.name || !val.description || !val.teamId || !val.stageId || val.files.length == 0) {
          console.log("false........")
          this.canSubmit = false
        }
        else {
          console.log("true........")
          this.canSubmit = true
        }
      },
      deep: true
    }
  },
  created: function () {
    const {
      id,
      uploadEnd,
      uploadStart,
      scoreQueryBegin,
    } = this.matchDetail.currentStage;
    this.formData.stageId = id;
    const nowTime = this.$moment(new Date()).valueOf();
    const uploadEndTime = this.$moment(uploadEnd).valueOf();
    const uploadStartTime = this.$moment(uploadStart).valueOf();
    const end_time = this.$moment(this.matchDetail.endTime).valueOf();
    const queryScoreTime = this.$moment(scoreQueryBegin).valueOf();
    //1 根据当前tab分别进行对应时间段的判断
    if (this.activeMenu === 'works') {
      // //1.1【提交作品页面】，判断当前阶段的比赛时间
      // if (nowTime < uploadStartTime) {
      //   this.message = '还未开始提交作品，敬请期待~';
      //   return;
      // } else if (nowTime > uploadEndTime) {
      //   this.message = '提交作品时间已经结束';
      //   return;
      // } else if (uploadStartTime <= nowTime && nowTime <= uploadEndTime) {
      //   //处于提交时间段，进一步判断（报名状态，资格）
      //   this.getMatchUploadStatus(id);
      // }
    } else {
      // this.getComplaint(id);
      // //1.2 【投诉页面】，判断时间
      // const endQueryTime = this.$moment(scoreQueryBegin)
      //   .add(3, 'days')
      //   .valueOf();
      // if (queryScoreTime <= nowTime && nowTime <= endQueryTime) {
      //   //可以提交，进一步判断（报名状态，资格）
      //   this.getMatchUploadStatus(id);
      // }
    }
  },
  methods: {
    handleProgress() {
      this.canSubmit = false
    },
    getComplaint(id) {
      getComplaint({ stageId: id }).then(res => {
        if (res.success) {
          if (!res.result.id) {
            this.hasComplaint = false;
          } else {
            this.hasComplaint = true;
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    getMatchUploadStatus(id) {
      getApplySatus({ matchId: this.matchDetail.id }).then((res) => {
        const { result = {}, success, message } = res;
        //1.1 没有报名或者没有资格,返回
        if (!success) {
          this.isApply = false;
          this.message = message;
          return;
        }
        //1.2 根据报名状态判断
        if (success && result.status === 2) {
          this.uploadStatus = false;
          //2.1 根据阶段ID判断（资格、作品状态）
          getWorksStatus({
            matchId: this.matchDetail.id,
            stageId: id,
          }).then((res) => {
            const { success, canUpload } = res;
            if (!success) {
              //获取失败，接口有问题
              this.$message.error(res.message)
              return;
            }
            if (this.activeMenu === 'works') {
              //可以参加，回填提交比赛作品的信息
              if (res && res.result) {
                const { name, description, fileList } = res.result;
                this.formData.name = name;
                this.formData.description = description;
                this.formData.files = fileList
                fileList.forEach(item => {
                  this.fileList.push({ name: item.fileOriginName, url: item.filePath })
                })
              }
            } else if (result) {
              //只有提交了作品，才能投诉
              this.matchStatus = 'start';
            }
            this.formData.teamId = result.teamId;
          });
        } else if (result.status === 3) {
          this.message = '您的报名信息没有通过，不能提交作品!';
        } else if (result.status === 1) {
          this.message = '您的报名信息还在审核中，不能提交作品!';
        }
      });
    },
    submitUpload() {
      this.$refs.uploadForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          submitWorksMulti(this.formData).then(res => {
            if (res.success) {
              this.$notify({
                title: '提交成功',
                message: res.message,
                type: 'success',
              });
              // window.location.reload();
            } else {
              this.$notify({
                title: '提交失败',
                message: res.message,
                type: 'error',
              });
            }
            this.loading = false;
          })
          // this.$refs.uploadForm.submit();
        } else {
          //验证不通过
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      // console.log(file, this.fileList, this.formData.files)
      this.fileList = this.fileList.filter(item => item.name != file.name)
      this.formData.files = this.formData.files.filter(item => item.fileOriginName != file.name)
    },
    handleChange(file, fileList) {
    },
    handlePreview(file) {
      // console.log(file)
      window.open(file.url);
    },
    handleExceed(files, fileList) {
    },
    handleFileSuccess(response, file, fileList) {
      this.canSubmit = true
      this.loading = false;
      if (response.success) {
        this.fileList.push({ name: response.result.fileOriginName, url: response.result.filePath })
        this.formData.files.push(response.result);
        this.$notify({
          title: '成功',
          message: '上传成功',
          type: 'success',
        });
      } else {
        this.$notify({
          title: '失败',
          message: response.message,
          type: 'error',
        });
      }
    },
    handleFileError(err, file, fileList) {
      this.loading = false;
      this.$notify.error({
        title: '错误',
        message: '提交失败',
      });
    },
  },
};
</script>

<style scoped>
.content-upload /deep/ .el-form-item__error {
  /* top: 75%;
  left: -33px; */
}
.content-upload /deep/ .el-upload-dragger {
  width: 640px;
}
.form-type {
  border-left: 2px solid #aaaaaa;
  padding-left: 10px;
  width: 800px;
  height: 26px;
  /* border: 2px solid #aaaaaa; */
  background-color: rgba(145, 70, 70, 0);
  /* box-sizing: border-box; */
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #aaaaaa;
  text-align: left;
  line-height: 18px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
