<template>
  <div class="content-join">
    <div
      v-if="currentStatus == 'notStart'"
      class="center match-not-start"
    >
      <div class="div-info">比赛未开始</div>
      <div class="center match-infos">
        <span>比赛未开始报名，敬请期待~ </span>
        <span>比赛报名时间：{{
            $moment(matchDetail.enrollStart).format('YYYY-MM-DD HH:mm')
          }}
          ---
          {{ $moment(matchDetail.enrollEnd).format('YYYY-MM-DD HH:mm') }}</span>
      </div>
    </div>
    <div
      v-else-if="currentStatus == 'end'"
      class="center match-end"
    >
      <div class="div-info">比赛报名已截止</div>
      <div class="center match-infos">
        <span>比赛报名已截止，欢迎参赛~</span>
        <span>比赛报名时间：{{
            $moment(matchDetail.enrollStart).format('YYYY-MM-DD HH:mm')
          }}
          ---
          {{ $moment(matchDetail.enrollEnd).format('YYYY-MM-DD HH:mm') }}</span>
      </div>
    </div>
    <div
      v-else
      class="center"
    >
      <span
        class="tip-text"
        v-if="joinStatus == 'success'"
      >您已经报名成功，祝您比赛顺利</span>
      <span
        class="tip-text-error"
        v-else-if="joinStatus == 'fail'"
      >您的报名信息审核未通过，请重新填写</span>
      <span class="tip-text">比赛报名时间：{{
          $moment(matchDetail.enrollStart).format('YYYY-MM-DD HH:mm')
        }}
        ---
        {{ $moment(matchDetail.enrollEnd).format('YYYY-MM-DD HH:mm') }}</span>
    </div>
    <div
      class="center match-start"
      v-if="currentStatus != 'end' && joinStatus != 'success'"
    >
      <div class="div-info">请填写报名参赛基本信息</div>
      <el-form
        :model="formData"
        ref="joinForm"
        label-width="140px"
        class="demo-formData"
        status-icon
      >
        <template v-if="matchDetail.id == hideMatchId">
          <el-form-item
            v-for="item in inputItems2"
            :key="item.dataIndex"
            :label="item.label + ':'"
            :prop="item.dataIndex"
            :rules="item.rules"
          >
            <div v-if="item.inputType === 'input' && item.dataIndex === 'verifyCode'">
              <el-input
                v-model="formData[item.dataIndex]"
                :placeholder="'请输入' + item.label"
                style="width: 440px"
                :disabled="inputDisabled"
              ></el-input>
              <el-button
                :type="shouldSend ? 'primary' : 'info'"
                style="width: 160px; margin-left: 40px"
                :disabled="inputDisabled || times > 0"
                @click="sendVerifyCode()"
              ><span v-if="times > 0">未收到？ {{ times }}s后可再次发送</span><span v-else-if="!sendLoading">发送验证码</span><span v-else>发送中...</span></el-button>
            </div>
            <template v-else-if="item.inputType === 'input'">
              <!-- <el-input
            v-if="item.dataIndex == 'teamName'"
            v-model="formData[item.dataIndex]"
            v-bind:placeholder="'请输入' + item.label"
            style="width: 640px"
            disabled
          ></el-input> -->
              <el-input
                v-model="formData[item.dataIndex]"
                :placeholder="'请输入' + item.label"
                style="width: 640px"
                :disabled="inputDisabled"
              ></el-input>
            </template>
            <el-select
              v-else-if="item.inputType === 'select'"
              v-model="formData[item.dataIndex]"
              :placeholder="'请选择' + item.label"
              style="width: 640px"
              filterable
              :disabled="inputDisabled"
            >
              <el-option
                v-for="option in options[item.dataIndex]"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item
            v-for="item in inputItems"
            :key="item.dataIndex"
            :label="item.label + ':'"
            :prop="item.dataIndex"
            :rules="item.rules"
          >
            <div v-if="item.inputType === 'input' && item.dataIndex === 'verifyCode'">
              <el-input
                v-model="formData[item.dataIndex]"
                :placeholder="'请输入' + item.label"
                style="width: 440px"
                :disabled="inputDisabled"
              ></el-input>
              <el-button
                :type="shouldSend ? 'primary' : 'info'"
                style="width: 160px; margin-left: 40px"
                :disabled="inputDisabled || times > 0"
                @click="sendVerifyCode()"
              ><span v-if="times > 0">未收到？ {{ times }}s后可再次发送</span><span v-else-if="!sendLoading">发送验证码</span><span v-else>发送中...</span></el-button>
            </div>
            <template v-else-if="item.inputType === 'input'">
              <!-- <el-input
            v-if="item.dataIndex == 'teamName'"
            v-model="formData[item.dataIndex]"
            v-bind:placeholder="'请输入' + item.label"
            style="width: 640px"
            disabled
          ></el-input> -->
              <el-input
                v-model="formData[item.dataIndex]"
                :placeholder="'请输入' + item.label"
                style="width: 640px"
                :disabled="inputDisabled"
              ></el-input>
            </template>
            <el-select
              v-else-if="item.inputType === 'select'"
              v-model="formData[item.dataIndex]"
              :placeholder="'请选择' + item.label"
              style="width: 640px"
              filterable
              :disabled="inputDisabled"
            >
              <el-option
                v-for="option in options[item.dataIndex]"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>

        <!-- 成员信息 -->
        <div v-if="matchDetail.id != hideMatchId">
          <div class="div-info">请填写成员信息</div>
          <div
            v-for="(n, index) in formData.teamNum"
            :key="n"
          >
            团队成员{{ n }}
            <el-form-item
              v-for="item in memberItems"
              :key="item.dataIndex"
              :label="item.label + ':'"
              :prop="'members[' + index + '].' + item.dataIndex"
              :rules="item.rules"
            >
              <el-input
                v-if="item.inputType === 'input'"
                v-model="formData.members[index][item.dataIndex]"
                :placeholder="'请输入' + item.label"
                style="width: 640px"
                :disabled="inputDisabled"
              ></el-input>
              <el-select
                v-if="item.inputType === 'select'"
                v-model="formData.members[index][item.dataIndex]"
                :placeholder="'请选择' + item.label"
                style="width: 640px"
                filterable
                :disabled="inputDisabled"
              >
                <el-option
                  v-for="option in options[item.dataIndex]"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <el-form-item>
          <div class="submit-buttons">
            <!-- <el-button type="primary" @click="addForm('joinForm')">保存</el-button> -->
            <el-button
              type="primary"
              @click="onClickSubmit('joinForm')"
              :disabled="inputDisabled || currentStatus !== 'start'"
            >提交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import { hideMatchId, submitApplyInfo, getApplySatus, sendApplyCode, editApplyDetail } from '@/api/match';
const phoneValidator = (rule, value, callback) => {
  if (!/^1[0-9]{10}$/.test(value)) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
};
import { sensitiveFilter } from '@/api/common'
const sensitiveValidator = (rule, value, callback) => {
  sensitiveFilter({ desc: value }).then(res => {
    if (!res.success) {
      callback(new Error(res.message));
    } else {
      callback();
    }
  })
}
export default {
  name: "joinFormSingle",
  props: {
    joinFormData: null
  },
  data() {
    return {
      hideMatchId,
      registrationStatus: 'start', //报名状态
      matchStatus: 'start', //比赛状态
      inputDisabled: false,
      sendLoading: false, //是否在发送验证码
      times: 0,
      joinStatus: 'no', //报名状态，默认未报名
      options: {
        groupType: [],
        teamNum: [],
        school: [
          { label: '学校1', value: '1' },
          { label: '学校2', value: '2' },
          { label: '学校3', value: '3' },
        ],
        grade: [
          { label: '幼儿园小班', value: '幼儿园小班' },
          { label: '幼儿园中班', value: '幼儿园中班' },
          { label: '幼儿园大班', value: '幼儿园大班' },
          { label: '小学1年级', value: '小学1年级' },
          { label: '小学2年级', value: '小学2年级' },
          { label: '小学3年级', value: '小学3年级' },
          { label: '小学4年级', value: '小学4年级' },
          { label: '小学5年级', value: '小学5年级' },
          { label: '小学6年级', value: '小学6年级' },
          { label: '初中1年级', value: '初中1年级' },
          { label: '初中2年级', value: '初中2年级' },
          { label: '初中3年级', value: '初中3年级' },
          { label: '高中1年级', value: '高中1年级' },
          { label: '高中2年级', value: '高中2年级' },
          { label: '高中3年级', value: '高中3年级' },
          { label: '高职1年级', value: '高职1年级' },
          { label: '高职2年级', value: '高职2年级' },
          { label: '高职3年级', value: '高职3年级' },
          { label: '大学1年级', value: '大学1年级' },
          { label: '大学2年级', value: '大学2年级' },
          { label: '大学3年级', value: '大学3年级' },
          { label: '大学4年级', value: '大学4年级' },
        ],
        className: [],
      },
      formData: {
        groupType: '',
        teamName: '',
        teamLeader: '',
        teacher1: '',
        teacherUnit1: '',
        teacher2: '',
        teacherUnit2: '',
        contact: '',
        verifyCode: '',
        teamNum: '',
        members: [],
      },
      inputItems: [
        {
          groupType: '',
          dataIndex: 'groupType',
          label: '选择参赛组别',
          inputType: 'select',
          rules: {
            required: true,
            message: '请选择参赛组别',
            trigger: 'change',
          },
        },
        {
          teamName: '',
          dataIndex: 'teamName',
          label: '团队名称',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入团队名称，并在8个字符以内',
              trigger: 'change',
              max: 8,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teamLeader: '',
          dataIndex: 'teamLeader',
          label: '团队负责人',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入团队负责人，并在10个字符以内',
              trigger: 'change',
              max: 10,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teacher1: '',
          dataIndex: 'teacher1',
          label: '团队指导教师1',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入团队指导教师1，并在10个字符以内',
              trigger: 'change',
              max: 10,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teacherUnit1: '',
          dataIndex: 'teacherUnit1',
          label: '指导教师1单位',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入指导教师1单位，并在30个字符以内',
              trigger: 'change',
              max: 30,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teacher2: '',
          dataIndex: 'teacher2',
          label: '团队指导教师2',
          inputType: 'input',
          rules: [
            {
              message: '请输入团队指导教师2，并在10个字符以内',
              trigger: 'change',
              max: 10,
              // required: true,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teacherUnit2: '',
          dataIndex: 'teacherUnit2',
          label: '指导教师2单位',
          inputType: 'input',
          rules: [
            {
              // required: true,
              message: '请输入指导教师2单位，并在30个字符以内',
              trigger: 'change',
              max: 30,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          contact: '',
          dataIndex: 'contact',
          label: '团队联系电话',
          inputType: 'input',
          rules: {
            required: true,
            message: '请输入团队联系电话',
            trigger: 'change',
            // validator: phoneValidator,
            pattern: /^1[0-9]{10}$/,
          },
        },
        // {
        //   verifyCode: '',
        //   dataIndex: 'verifyCode',
        //   label: '输入短信验证码',
        //   inputType: 'input',
        //   rules: {
        //     required: true,
        //     message: '请输入短信验证码',
        //     trigger: 'change',
        //   },
        // },
        {
          teamNum: 1,
          dataIndex: 'teamNum',
          label: '选择团队人数',
          inputType: 'select',
          rules: {
            required: true,
            message: '请选择团队人数',
            trigger: 'change',
          },
        },
      ],
      inputItems2: [
        {
          groupType: '',
          dataIndex: 'groupType',
          label: '选择参赛组别',
          inputType: 'select',
          rules: {
            required: true,
            message: '请选择参赛组别',
            trigger: 'change',
          },
        },
        {
          teacherUnit2: '',
          dataIndex: 'teacherUnit2',
          label: '作品名称',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入作品名称',
              trigger: 'change',
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teamName: '',
          dataIndex: 'teamName',
          label: '参赛者姓名',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入参赛者姓名，并在8个字符以内',
              trigger: 'change',
              max: 8,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teamLeader: '',
          dataIndex: 'teamLeader',
          label: '参赛者单位/学校',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入参赛者单位/学校',
              trigger: 'change',
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teacher1: '',
          dataIndex: 'teacher1',
          label: '指导教师',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入指导教师，并在10个字符以内',
              trigger: 'change',
              max: 10,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          teacherUnit1: '',
          dataIndex: 'teacherUnit1',
          label: '指导教师单位',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入指导教师单位，并在30个字符以内',
              trigger: 'change',
              max: 30,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          contact: '',
          dataIndex: 'contact',
          label: '参赛者手机号',
          inputType: 'input',
          rules: {
            required: true,
            message: '请输入参赛者手机号',
            trigger: 'change',
            // validator: phoneValidator,
            pattern: /^1[0-9]{10}$/,
          },
        },
        // {
        //   verifyCode: '',
        //   dataIndex: 'verifyCode',
        //   label: '输入短信验证码',
        //   inputType: 'input',
        //   rules: {
        //     required: true,
        //     message: '请输入短信验证码',
        //     trigger: 'change',
        //   },
        // },
        // {
        //   teamNum: 1,
        //   dataIndex: 'teamNum',
        //   label: '选择团队人数',
        //   inputType: 'select',
        //   rules: {
        //     required: true,
        //     message: '请选择团队人数',
        //     trigger: 'change',
        //   },
        // },
      ],
      memberItems: [
        {
          name: '',
          dataIndex: 'name',
          label: '姓名',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入姓名，并在5个字符以内',
              trigger: 'change',
              max: 5,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          name: '',
          dataIndex: 'identity',
          label: '身份证',
          inputType: 'input',
          rules: {
            required: true,
            message: '请输入有效身份证号码',
            trigger: 'blur',
            pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
          },
        },
        {
          contact: '',
          dataIndex: 'contact',
          label: '联系电话',
          inputType: 'input',
          rules: {
            required: true,
            message: '请输入11位联系电话',
            trigger: 'change',
            pattern: /^1[0-9]{10}$/,
          },
        },
        {
          school: '',
          dataIndex: 'school',
          label: '所在学校',
          inputType: 'input',
          rules: [
            {
              required: true,
              message: '请输入学校全称',
              trigger: 'change',
              max: 20,
            },
            { validator: sensitiveValidator, trigger: 'change' }
          ],
        },
        {
          grade: '',
          dataIndex: 'grade',
          label: '所在年级',
          inputType: 'select',
          rules: {
            required: true,
            message: '请选择所在年级',
            trigger: 'change',
          },
        },
        {
          class: '',
          dataIndex: 'className',
          label: '所在班级',
          inputType: 'select',
          rules: {
            required: true,
            message: '请选择所在班级',
            trigger: 'change',
          },
        },
      ],
    };
  },
  created: function () {
    if (this.joinFormData != null && this.joinFormData != '') {
      this.$nextTick(() => {
        this.formData = {
          groupType: this.joinFormData.matchLevelId,
          teamName: this.joinFormData.name,
          teamLeader: this.joinFormData.leader,
          teacher1: this.joinFormData.masterTutor,
          teacherUnit1: this.joinFormData.masterTutorInst,
          teacher2: this.joinFormData.secondTutor,
          teacherUnit2: this.joinFormData.secondTutorInst,
          contact: this.joinFormData.contact,
          verifyCode: '   ',
          teamNum: this.joinFormData.memberCount,
          members: [...this.joinFormData.members],
        }
        this.$set(this.formData, members, this.joinFormData.members)
      })

      if (this.joinFormData.members && this.joinFormData.members.length == 0) {
        this.formData.members = [
          {
            name: '',
            identity: '',
            contact: '',
            school: '',
            grade: '',
            className: '',
          },
        ];
      } else {
        for (let i in this.joinFormData.members) {
          let item = this.joinFormData.members[i];
          this.$set(this.formData, i, item)
        }
      }
      this.$forceUpdate();
    }
    const {
      allowMemberCount = [],
      startTime,
      endTime,
      enrollStart,
      enrollEnd,
      levelSetting,
    } = this.matchDetail;
    //1.1 渲染最多人数
    let maxNum = 0; //最多人数
    let minNum = 1; //最少人数
    let members = [];
    let teamNumOptions = [];
    let classNameOptions = [];
    let groupTypeOptions = [];
    allowMemberCount.map((member) => {
      teamNumOptions.push({ label: `${member}个人`, value: member });
      maxNum = member >= maxNum ? member : maxNum;
      minNum = member >= minNum ? minNum : member;
    });

    for (let i = 0; i < maxNum; i++) {
      members.push({
        name: '',
        identity: '',
        contact: '',
        school: '',
        grade: '',
        className: '',
      });
    }
    for (let i = 0; i < 40; i++) {
      classNameOptions.push({ label: `班级${i + 1}`, value: `班级${i + 1}` });
    }
    this.formData.members = members;
    this.formData.teamNum = minNum;


    this.options.teamNum = teamNumOptions;
    this.options.className = classNameOptions;
    this.options.groupType = levelSetting;

  },
  computed: {
    matchDetail() {
      return this.$store.state.matchDetail;
    },
    //是否可以请求发送验证码
    shouldSend() {
      return /^1[0-9]{10}$/.test(this.formData.contact);
    },
    currentStatus() {
      if (this.matchStatus === 'start') {
        //比赛进行中
        // if(this.registrationStatus==='notStart'){}
        return this.registrationStatus;
      } else {
        return this.matchStatus;
      }
    },
  },
  methods: {
    onClickSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(`确定提交？（注意：报名截止后，不能再修改）`)
            .then(() => {
              // console.log('submit', this.formData);
              const {
                groupType,
                teamName,
                teamLeader,
                teacher1,
                teacherUnit1,
                teacher2,
                teacherUnit2,
                contact,
                verifyCode,
                teamNum,
                members,
              } = this.formData;
              const memberList = [];
              for (let i = 0; i < teamNum; i++) {
                const member = {
                  name: members[i]['name'],
                  identity: members[i]['identity'],
                  contact: members[i]['contact'],
                  school: members[i]['school'],
                  grade: members[i]['grade'],
                  className: members[i]['className'],
                };
                memberList.push(member);
              }
              // const requestParams = {
              const matchApply = {
                contact: contact,
                leader: teamLeader,
                masterTutor: teacher1,
                masterTutorInst: teacherUnit1,
                matchId: this.matchDetail.id,
                matchLevel: groupType,
                memberCount: teamNum,
                name: teamName,
                secondTutor: teacher2,
                secondTutorInst: teacherUnit2,
                members: memberList,
                smsCode: verifyCode,
              };
              if (this.joinFormData != null && this.joinFormData != '') {
                matchApply.teamId = this.joinFormData.id;
                editApplyDetail(matchApply)
                  .then((res) => {
                    //报名成功，刷新状态
                    if (res.success) {
                      this.$notify({
                        title: '信息提示',
                        message: '修改成功',
                        type: 'success',
                      });
                      // this.getApplyStatus();
                      this.$emit('close');
                    } else {
                      this.$notify({
                        title: '信息提示',
                        message: res.message,
                        type: 'warning',
                      });
                    }
                  })
                  .catch((res) => {
                    console.log(res, '报名错误');
                  });
              } else {
                submitApplyInfo(matchApply)
                  .then((res) => {
                    //报名成功，刷新状态
                    if (res.success) {
                      this.$notify({
                        title: '信息提示',
                        message: '报名成功',
                        type: 'success',
                      });
                      // this.getApplyStatus();
                      this.$emit('close');
                    } else {
                      this.$notify({
                        title: '信息提示',
                        message: res.message,
                        type: 'warning',
                      });
                    }
                  })
                  .catch((res) => {
                    console.log(res, '报名错误');
                  });
              }

            })
            .catch(() => { });
        }
      });
    },
    getApplyStatus() {
      getApplySatus({ matchId: this.matchDetail.id }).then((res) => {
        const { result = {}, success } = res;
        if (!success) {
          return;
        }
        const { status } = result;
        if (status !== 0) {
          //已经报过名,回填信息
          this.formData = {
            groupType: result.matchLevel,
            teamName: result.name,
            teamLeader: result.leader,
            teacher1: result.masterTutor,
            teacherUnit1: result.masterTutorInst,
            teacher2: result.secondTutor,
            teacherUnit2: result.secondTutorInst,
            contact: result.contact,
            verifyCode: '   ',
            teamNum: result.memberCount,
            members: [...result.members],
          };
          if (result.members && result.members.length == 0) {
            this.formData.members = [
              {
                name: '',
                identity: '',
                contact: '',
                school: '',
                grade: '',
                className: '',
              },
            ];
          }
        }
        if (status == 1 || status == 2) {
          //信息审核中或报名成功
          this.inputDisabled = true;
          if (status == 2) {
            this.joinStatus = 'success';
          } else {
            this.joinStatus = 'join';
          }
        } else if (status == 3) {
          //审核不通过，报名失败
          this.matchStatus = 'fail';
        }
      });
    },
    sendVerifyCode() {
      this.$refs['joinForm'].validateField('contact', (error) => {
        if (!error) {
          if (this.times == 0 && !this.sendLoading) {
            this.sendLoading = true;
            sendApplyCode({
              phone: this.formData.contact,
              scene: 3,
            }).then(
              (res) => {
                this.sendLoading = false;
                if (res.success) {
                  this.$notify({
                    title: '系统提示',
                    message: res.message || '发送成功',
                    type: 'success',
                  });
                  this.times = 59;
                  this.setI = setInterval(() => {
                    this.times -= 1;
                    if (this.times == 0) {
                      clearInterval(this.setI);
                    }
                  }, 1000);
                } else {
                  this.$notify({
                    title: '系统提示',
                    message: res.message || '登录失败：未知错误',
                    type: 'warning',
                  });
                }
              },
              (error) => {
                this.sendLoading = false;
                this.$notify({
                  title: '系统提示',
                  message: error || '登录失败：未知错误',
                  type: 'warning',
                });
              },
            );
          }
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.content-join /deep/ .el-form-item__error {
  /* top: 75%;
  left: -33px; */
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
