<template>
  <div>
    <div class="content-join">
      <div v-if="currentStatus == 'notStart'" class="center match-not-start">
        <div class="div-info">比赛未开始</div>
        <div class="center match-infos">
          <span>比赛未开始报名，敬请期待~ </span>
          <span
            >比赛报名时间：{{
              $moment(matchDetail.enrollStart).format('YYYY-MM-DD HH:mm')
            }}
            ---
            {{
              $moment(matchDetail.enrollEnd).format('YYYY-MM-DD HH:mm')
            }}</span
          >
        </div>
      </div>
      <div v-else-if="currentStatus == 'end'" class="center match-end">
        <div class="div-info">比赛报名已截止</div>
        <div class="center match-infos">
          <span>比赛报名已截止，欢迎参赛~</span>
          <span
            >比赛报名时间：{{
              $moment(matchDetail.enrollStart).format('YYYY-MM-DD HH:mm')
            }}
            ---
            {{
              $moment(matchDetail.enrollEnd).format('YYYY-MM-DD HH:mm')
            }}</span
          >
        </div>
      </div>
    </div>
    <div>
      <div class="table-ope">
        <div class="btns" v-if="registrationStatus == 'start'">
          <el-button @click="initJoinForm()" type="primary" style="float: left">
            <template v-if="matchDetail.id == hideMatchId">新增作品信息</template>
            <template v-else>新增单个报名队伍</template></el-button
          >
          <template v-if="matchId != hideMatchId">
            <el-upload
              style="float: left; margin: 0px 10px"
              ref="upload"
              :action="'/api/matching/apply/batch/' + this.matchId"
              :show-file-list="false"
              :limit="1"
              :headers="headers"
              :on-success="(response) => handleFileSuccess(response, 'team')"
              :on-error="(response) => handleFileError(response, 'team')"
            >
              <el-button
                slot="trigger"
                type="primary"
                v-loading.fullscreen.lock="fullscreenLoading"
                >批量上传报名队伍</el-button
              >
            </el-upload>
            <el-button
              class="my-export-btn"
              @click="exportTemplate('录入模板')"
              type="warning"
              >下载录入模板</el-button
            >
          </template>
        </div>
        <div class="tips" v-if="matchId != hideMatchId">
          <p>*1个账号最多可以报名<span>30支</span>参赛队伍</p>
          <p>*批量上传报名，请在<span>录入模板</span>填写信息</p>
        </div>
        <el-upload
          v-if="
            registrationStatus == 'start' && matchDetail.needAttachment == 1
          "
          style="float: left; margin: 0px 10px"
          ref="uploadFile"
          :action="'/api/v1/match/upload?matchId=' + this.matchId"
          :show-file-list="false"
          :limit="1"
          :headers="headers"
          :on-success="(response) => handleFileSuccess(response, 'file')"
          :on-error="(response) => handleFileError(response, 'file')"
        >
          <el-button
            slot="trigger"
            type="primary"
            v-loading.fullscreen.lock="fullscreenLoading"
            >
            <template v-if="matchDetail.id == hideMatchId">上传报名表扫描件</template>
            <template v-else>上传报名附件</template>
            </el-button
          >
        </el-upload>
        <div class="clear"></div>
      </div>
      <div class="table-content">
        <el-table
          v-if="matchId == hideMatchId"
          :data="joinFormData"
          border
          style="width: 100%"
        >
          <el-table-column prop="matchLevelId" label="*参赛组别" width="120">
          </el-table-column>
          <el-table-column prop="secondTutorInst" label="作品名称" width="120">
          </el-table-column>
          <el-table-column prop="name" label="参赛者姓名" width="120">
          </el-table-column>
          <el-table-column prop="leader" label="*参赛者单位/学校" width="150">
          </el-table-column>
          <el-table-column prop="masterTutor" label="*指导教师" width="120">
          </el-table-column>
          <el-table-column
            prop="masterTutorInst"
            label="*指导教师单位"
            width="120"
          >
          </el-table-column>
          <el-table-column prop="contact" label="*参赛者手机号" width="120">
          </el-table-column>
          <el-table-column label="报名状态" width="120">
            <template slot-scope="scope">
              {{ scope.row.status | statusFilter }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="handleReview(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                @click="handleDelete(scope.row.id)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-table v-else :data="joinFormData" border style="width: 100%">
          <el-table-column prop="matchLevelId" label="*参赛组别" width="120">
          </el-table-column>
          <el-table-column prop="name" label="团队名称" width="120">
          </el-table-column>
          <el-table-column prop="leader" label="*团队负责人" width="120">
          </el-table-column>
          <el-table-column
            prop="masterTutor"
            label="*团队指导教师1"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="masterTutorInst"
            label="*指导教师1单位"
            width="120"
          >
          </el-table-column>
          <el-table-column prop="secondTutor" label="团队指导教师2" width="120">
          </el-table-column>
          <el-table-column
            prop="secondTutorInst"
            label="指导教师2单位"
            width="120"
          >
          </el-table-column>
          <el-table-column prop="contact" label="*团队联系电话" width="120">
          </el-table-column>
          <el-table-column prop="memberCount" label="*团队人数" width="120">
          </el-table-column>
          <template v-for="i in 2">
            <el-table-column
              :prop="'member' + i + 'Name'"
              :label="'团队成员' + i + '姓名'"
              width="120"
            >
            </el-table-column>
            <el-table-column
              :prop="'member' + i + 'Identity'"
              :label="'团队成员' + i + '身份证号'"
              width="150"
            >
            </el-table-column>
            <el-table-column
              :prop="'member' + i + 'Contact'"
              :label="'团队成员' + i + '联系电话'"
              width="150"
            >
            </el-table-column>
            <el-table-column
              :prop="'member' + i + 'School'"
              :label="'团队成员' + i + '所在学校'"
              width="150"
            >
            </el-table-column>
            <el-table-column
              :prop="'member' + i + 'Grade'"
              :label="'团队成员' + i + '所在年级'"
              width="150"
            >
            </el-table-column>
            <el-table-column
              :prop="'member' + i + 'Class'"
              :label="'团队成员' + i + '所在班级'"
              width="150"
            >
            </el-table-column>
          </template>
          <el-table-column prop="workName" label="作品名称" width="120">
          </el-table-column>
          <el-table-column prop="workDes" label="作品说明" width="120">
          </el-table-column>
          <el-table-column label="附件地址" width="120">
            <template slot-scope="scope">
              <div v-for="(item, i) in scope.row.workFiles" :key="i">
                <a class="my-link" target="_blank" :href="item.filePath">{{
                  item.fileOriginName
                }}</a
                ><br />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报名状态" width="120">
            <template slot-scope="scope">
              {{ scope.row.status | statusFilter }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="handleReview(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                @click="handleDelete(scope.row.id)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="390px"
      :before-close="handleDialogClose"
    >
      <el-result icon="success" v-if="importResult == true">
        <template slot="title">
          <span class="my-success-text">{{ importMessage }}</span>
        </template>
        <template slot="extra">
          <el-button
            style="width: 290px"
            @click="handleDialogClose"
            type="primary"
            size="medium"
            >完成</el-button
          >
        </template>
      </el-result>
      <el-result icon="error" v-else>
        <template slot="title">
          <span class="my-error-text">
            <!-- <span class="light-text">25条数据成功</span>，5条数据失败 -->
            {{ importMessage }}
          </span>
        </template>
        <!-- <template slot="subTitle">
          <span class="my-error-text">请下载失败数据，重新填写调整</span>
        </template> -->
        <template slot="extra">
          <el-button
            style="width: 290px"
            @click="handleDialogClose"
            type="danger"
            size="medium"
            >确定</el-button
          >
        </template>
      </el-result>
    </el-dialog>
    <el-dialog
      :visible.sync="joinFormVisible"
      width="900px"
      :before-close="handleJoinFormClose"
    >
      <Join-form-single
        v-if="joinFormVisible"
        :joinFormData="dialogData"
        @close="handleJoinFormClose"
      ></Join-form-single>
    </el-dialog>
  </div>
</template>
<script>
import JoinFormSingle from '@/components/match/JoinFormSingle.vue';
import axios from 'axios';
import { downFile, uploadFile } from '@/api/common.js';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import Vue from 'vue';
const token = Vue.ls.get(ACCESS_TOKEN);
import {
  hideMatchId,
  getApplySatus,
  deleteApply,
  uploadAttachMent,
} from '@/api/match';

export default {
  components: {
    JoinFormSingle,
  },
  data() {
    return {
      hideMatchId,
      dialogData: null,
      fullscreenLoading: false,
      total: 0,
      tableData: [],
      dialogVisible: false,
      params: {
        pageSize: 10,
        pageNo: 1,
      },
      importResult: true,
      importMessage: null,
      joinFormVisible: false,
      joinFormData: [],
      matchId: null,
      headers: {
        'X-Access-Token': token,
      },
      registrationStatus: 'start', //报名状态
      matchStatus: 'start', //比赛状态
    };
  },
  computed: {
    matchDetail() {
      return this.$store.state.matchDetail;
    },
    currentStatus() {
      if (this.matchStatus === 'start') {
        //比赛进行中
        // if(this.registrationStatus==='notStart'){}
        return this.registrationStatus;
      } else {
        return this.matchStatus;
      }
    },
  },
  filters: {
    statusFilter(val) {
      if (val == 1) {
        return '信息审核中';
      } else if (val == 2) {
        return '报名成功';
      } else if (val == 3) {
        return '报名失败';
      }
    },
  },
  created() {
    this.matchId = this.$route.query.id;
    const {
      allowMemberCount = [],
      startTime,
      endTime,
      enrollStart,
      enrollEnd,
      levelSetting,
    } = this.matchDetail;

    const nowTime = this.$moment(new Date()).valueOf();
    const start_time = this.$moment(startTime).valueOf();
    const end_time = this.$moment(endTime).valueOf();
    const joinStartTime = this.$moment(enrollStart).valueOf();
    const joinEndTime = this.$moment(enrollEnd).valueOf();
    // console.log(this.matchDetail)
    // console.log('nowTime', nowTime,start_time,joinStartTime);
    if (nowTime < start_time) {
      //比赛未开始
      this.matchStatus = 'notStart';
    } else if (start_time <= nowTime && nowTime <= end_time) {
      // &&nowTime <= end_time
      //比赛进行中
      this.matchStatus = 'start';
    } else {
      //比赛已结束
      this.matchStatus = 'end';
    }
    if (nowTime < joinStartTime) {
      //报名未开始
      this.registrationStatus = 'notStart';
    } else if (joinStartTime <= nowTime && nowTime <= joinEndTime) {
      //报名进行中
      this.registrationStatus = 'start';
      this.initData();
    } else {
      //报名已结束
      this.registrationStatus = 'end';
    }
  },
  methods: {
    initData() {
      getApplySatus({ matchId: this.matchId }).then((res) => {
        const { result = {}, success } = res;
        if (!success) {
          this.joinFormData = [];
          return;
        } else {
          this.joinFormData = result.allTeams;
          for (let i in this.joinFormData) {
            let row = this.joinFormData[i];
            if (row.members && row.members.length > 0) {
              for (let j in row.members) {
                row['member' + (j * 1 + 1) + 'Name'] = row.members[j].name;
                row['member' + (j * 1 + 1) + 'Identity'] =
                  row.members[j].identity;
                row['member' + (j * 1 + 1) + 'Contact'] =
                  row.members[j].contact;
                row['member' + (j * 1 + 1) + 'School'] = row.members[j].school;
                row['member' + (j * 1 + 1) + 'Grade'] = row.members[j].grade;
                row['member' + (j * 1 + 1) + 'Class'] =
                  row.members[j].className;
              }
            }
            if (row.works && row.works.length > 0) {
              row['workName'] = row.works[0].name;
              row['workDes'] = row.works[0].description;
              row['workFiles'] = JSON.parse(row.works[0].files);
            }
          }
        }
      });
    },
    handleReview(row) {
      this.joinFormVisible = true;
      this.dialogData = row;
    },
    handleDelete(id) {
      deleteApply(id).then((res) => {
        if (!res.success) {
          this.$message.error(res.message);
        } else {
          this.initData();
        }
      });
    },
    handleDialogClose() {
      this.dialogVisible = false;
    },
    initJoinForm() {
      this.joinFormVisible = true;
    },
    handleJoinFormClose() {
      this.joinFormVisible = false;
      this.initData();
    },
    handleFileSuccess(response, type) {
      this.fullscreenLoading = false;
      if (type == 'team') {
        this.$refs.upload.clearFiles();
        this.dialogVisible = true;
        this.importResult = response.success;
        this.importMessage = response.message;
      } else {
        this.$refs.uploadFile.clearFiles();
        if (response.success) {
          let attachmentData = {
            matchId: this.matchId,
            attachmentUrl: response.result.filePath,
          };
          uploadAttachMent(attachmentData).then((res) => {
            this.$message.success('上传成功');
          });
        } else {
          this.$message.error(response.message);
        }
      }
      this.initData();
    },
    handleFileError(response, type) {
      this.fullscreenLoading = false;
      if (type == 'team') {
        this.$refs.upload.clearFiles();
        this.importResult = false;
        this.dialogVisible = true;
      } else {
        this.$refs.uploadFile.clearFiles();
        this.$message.error('上传失败');
      }
    },
    exportTemplate(fileName) {
      if (!fileName || typeof fileName != 'string') {
        fileName = '导出文件';
      }
      let param = { ...this.queryParam };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param['selections'] = this.selectedRowKeys.join(',');
      }
      // console.log("导出参数",param)
      let exportUrl = `/matching/apply/batch/${this.matchId}/xls`;
      downFile(exportUrl, param).then((data) => {
        if (!data) {
          this.$message.error('文件下载失败');
          return;
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(
            new Blob([data], { type: 'application/vnd.ms-excel' }),
            fileName + '.xls',
          );
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], { type: 'application/vnd.ms-excel' }),
          );
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName + '.xls');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.clear {
  clear: both;
}
.table-ope {
  .btns {
    float: left;
    margin-right: 10px;
  }
  .tips {
    float: left;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    p {
      color: #acacac;
    }
    span {
      color: #ffbd60;
    }
  }
}
.table-content {
  margin-top: 20px;
}
.table-pag {
  margin-top: 10px;
  justify-content: right;
  text-align: right;
}
.my-success-text {
  font-size: 20px;
  color: #409eff;
  line-height: 1.3;
  font-size: 18px;
}
.my-error-text {
  font-size: 20px;
  color: #f56c6c;
  line-height: 1.3;
  font-size: 18px;
  .light-text {
    color: #409eff;
  }
}
.my-export-btn {
  background-color: #ffbd60;
  border: 1px solid #ffbd60;
  &:hover {
    -webkit-filter: brightness(1.1);
    filter: brightness(1.1);
  }
}
.content-join {
  .div-info {
    border-left: 2px solid #aaa;
    padding-left: 10px;
    width: 800px;
    height: 26px;
    background-color: hsla(0, 0%, 100%, 0);
    font-family: Arial Regular, Arial, sans-serif;
    color: #aaa;
    text-align: left;
    line-height: 18px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .match-infos {
    color: #7f7f7f;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      color: #409eff;
      -webkit-transition: all 0.3s;
    }
  }
}
.my-link {
  color: #409eff;
  cursor: pointer;
  &:hover {
    -webkit-filter: brightness(1.1);
    filter: brightness(1.1);
  }
}
</style>