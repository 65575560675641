<template>
  <div  class="content-infos">
    <div>
      <!-- 图片组件 -->
      <div v-if="matchActiveTab[activeMenu]['type'] == 'img'" class="center">
        <img
          v-if="matchActiveTab[activeMenu]['name'] === '1'"
          :src="matchDetail.imgDesc"
          class="content-img"
        />
        <img
          v-if="matchActiveTab[activeMenu]['name'] === '2'"
          :src="matchDetail.imgRequired"
          class="content-img"
        />
        <img
          v-if="matchActiveTab[activeMenu]['name'] === '3'"
          :src="matchDetail.imgPrize"
          class="content-img"
        />
      </div>
      <!-- 附件组件 -->
      <div
        v-else-if="matchActiveTab[activeMenu]['type'] == 'attachment'"
        class="center content-attachment"
      >
        <el-tooltip
          v-if="matchStatus === 'end'"
          class="item"
          effect="dark"
          content="比赛已经结束，不支持下载附件"
          placement="top"
        >
          <a href="#">{{ matchDetail.attachName }}</a>
        </el-tooltip>
        <a v-else :href="matchDetail.attachSrc">{{ matchDetail.attachName }}</a>
      </div>
      <!-- 报名组件 -->
      <JoinForm
        v-else-if="matchActiveTab[activeMenu]['type'] == 'form'"
        :activeMenu="activeMenu"
        v-bind="$attrs"
      />
      <!-- 比赛状态组件 -->
      <MatchStatus
        v-else-if="matchActiveTab[activeMenu]['type'] == 'status'"
        :activeMenu="activeMenu"
        v-bind="$attrs"
      />
      <!-- 上传文件附件 -->
      <MatchUpload
        v-else-if="matchActiveTab[activeMenu]['type'] == 'upload'"
        :activeMenu="activeMenu"
        v-bind="$attrs"
      />
      <!-- 表格展示组件 -->
      <MatchTable
        v-else-if="matchActiveTab[activeMenu]['type'] == 'table'"
        :activeMenu="activeMenu"
        v-bind="$attrs"
      />
      <!-- 我的成绩组件 -->
      <MyResult
        v-else-if="matchActiveTab[activeMenu]['type'] == 'result'"
        :activeMenu="activeMenu"
        v-bind="$attrs"
      />
      <!-- 投诉组件 -->
      <MatchComplain
      v-else-if="matchActiveTab[activeMenu]['type'] == 'complain'"
        :activeMenu="activeMenu"
        v-bind="$attrs"/>
    </div>
    <div class="fix-opts judge-entery">
      <ul>
        <li @click="GoTo('/judge')" title="评委入口">
        <el-tooltip class="item" effect="dark" content="评委入口" placement="left">
          <img src="@/assets/image/match/to-judge.svg" alt="评委入口">
        </el-tooltip>
          <!-- <span slot="title">评委入口</span> -->
        </li>
        <li @click="GoTo('/match')" title="返回赛事">
          <el-tooltip class="item" effect="dark" content="返回赛事" placement="left">
            <img src="@/assets/image/match/to-return.svg" alt="返回赛事">
          </el-tooltip>
          <!-- <span slot="title">返回赛事</span> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import JoinForm from '@/components/match/JoinForm.vue';
import MatchStatus from '@/components/match/MatchStatus.vue';
import MatchTable from '@/components/match/MatchTable.vue';
import MatchUpload from '@/components/match/MatchUpload.vue';
import MyResult from '@/components/match/MyResult.vue';
import MatchComplain from '@/components/match/MatchComplain.vue';

export default {
  name: 'MatchDeatilContent',
  props: {
    activeMenu: String,
  },
  data() {
    return {
      matchStatus: 'start'
    };
  },
  computed: mapState({
    matchDetail: (state) => state.matchDetail,
    matchActiveTab: (state) => state.matchActiveTab,
    token: (state) => state.token,
  }),
  updated: function () {
    const { startTime, endTime } = this.matchDetail;
    const nowTime = this.$moment(new Date()).valueOf();
    const start_time = this.$moment(startTime).valueOf();
    const end_time = this.$moment(endTime).valueOf();
    if (nowTime < start_time) {
      //比赛未开始
      this.matchStatus = 'notStart';
    } else if (start_time <= nowTime && nowTime <= end_time) {
      // &&nowTime <= end_time
      //比赛进行中
      this.matchStatus = 'start';
    } else {
      //比赛已结束
      this.matchStatus = 'end';
    }
  },
  components: { JoinForm, MatchStatus, MatchTable, MatchUpload, MyResult,MatchComplain },
  methods: {
    handleFixMenu(index, indexPath){
      if(index == "/judge"){
        return this.gotoJudgePage()
      }
      this.$router.push("/match")
    },
    GoTo(path){
      if(path == "/judge"){
        return this.gotoJudgePage()
      }
      this.$router.push("/match")
    },
    gotoJudgePage(){
      window.open(`/match-judges?id=${this.matchDetail.id}`);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
